import React from 'react';
import {
    Row, Col, Spin, Input, Button
} from 'antd';
import './ImageTagArea.css';

export default function () {
    const { TextArea } = Input;
    const { caption } = this.state;
    const { imageTagModeOn, loading } = this.props;

    if (!imageTagModeOn) {
        return null;
    }

    return (
        <div className="image-tag-area-block">
            <div className="container">
                <Spin spinning={loading}>
                    <Row type="flex">
                        <Col sm={14}>
                            <form className="actors-form">
                                {this.state.actors.map((actor, index) => (
                                    <div key={actor.id} className="actor-name">
                                        {!this.isMobileDevice() && (
                                            <div className="key__button">
                                                <div
                                                    className={`${
                                                        this.state.flashIndex === actor.id
                                                        && this.state.selectedActors[actor.id]
                                                            ? 'flash'
                                                            : this.state.flashIndex === actor.id
                                                                ? 'flash-red'
                                                                : ''
                                                    }`}>
                                                    {parseInt(index, 10) + 1 <= 9
                                                        ? parseInt(index, 10) + 1
                                                        : this.qwertyKeys[parseInt(index, 10) - 9]}
                                                </div>
                                            </div>)}
                                        <input
                                            ref={ref => {
                                                this.checkboxRefs[actor.id] = ref;
                                            }}
                                            style={{ marginRight: '10px' }}
                                            type="checkbox"
                                            id={`actorCheckbox_${actor.id}`}
                                            checked={this.state.selectedActors[actor.id] || false}
                                            onChange={() => this.toggleNewActorSelection(actor.id)} />
                                        <span style={{ color: this.state.selectedActors[actor.id] ? '#52c41a' : null }}>
                                            {actor.username}
                                        </span>
                                    </div>
                                ))}
                            </form>
                        </Col>
                    </Row>
                </Spin>
                <Row type="flex" justify="space-around">
                    <div>
                        <div className="shortcut-number">
                            0
                        </div>
                        <Button type="link" className="custom-link-button" icon="history" onClick={() => this.loadTagHistory()}>
                            Repeat history
                        </Button>
                    </div>
                    <div>
                        <div className="shortcut-number">
                            X
                        </div>
                        <Button type="link" className="custom-link-button" icon="close" onClick={() => this.handleClearAll()}>
                            Clear all tags
                        </Button>
                    </div>
                </Row>
            </div>
        </div>
    );
}
