import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
    Modal, Checkbox, Row, Col,
} from 'antd';
import { connect } from 'react-redux';
import lockr from 'lockr';
import _ from 'underscore';
import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';
import DOM from './imageSingle';

const CONFIRM_DIALOG = 'confirm-dialog';

const {
    APP_DEBUG,
    BASE_URL,
    BASE_URL_DEBUG
} = require('../../core/constants').default;

class ImageSingle extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.preloadedImageKeys = [];
        this.state = {
            taggedUserModelVisible: false,
            newUserTag: null,
            warningDialogChange: false,
            statusExplanationModalOpen: false,
            renderedImageId: null,
            imageChanged: false,
            isImageLoading: true,
        };
    }

    componentDidMount() {
        const { actions, params } = this.props;
        actions.resetPreloadImagesRequest();
        actions.getAllActorsRequest({
            production: params.production,
            day: params.day,
        });
    }

    componentDidUpdate(prevProps) {
        const {
            selectedProductionDayImage, actions, buttonsArray
        } = this.props;
        let imageUrl = null;
        const buttons = buttonsArray[this.state.renderedImageId];
        const selectedProductionDayImageId = selectedProductionDayImage && +selectedProductionDayImage.id;
        const preSelectedProductionDayImageId = prevProps.selectedProductionDayImage && +prevProps.selectedProductionDayImage.id;

        const renderedImageId = +this.state.renderedImageId;
        const isImageLoading = +this.state.isImageLoading;

        if (!this.state.imageChanged || prevProps.selectedProductionDayImage !== selectedProductionDayImage) {
            if (selectedProductionDayImageId && selectedProductionDayImageId !== renderedImageId && !isImageLoading) {
                this.setState({ renderedImageId: selectedProductionDayImageId });
            }
        }

        if (renderedImageId && selectedProductionDayImage) {
            if (selectedProductionDayImage && selectedProductionDayImage.publicUrl) {
                const keyExists = this.preloadedImageKeys.find((v) => v === renderedImageId);
                if (!keyExists) {
                    imageUrl = `${APP_DEBUG === true ? BASE_URL_DEBUG : BASE_URL}/display-image/${selectedProductionDayImage.publicUrl}.jpg`;
                    actions.preloadImagesRequest({ id: renderedImageId, url: imageUrl });
                    this.preloadedImageKeys.push(renderedImageId);
                }
            }

            if (buttons && buttons.previousImagePublicUrl) {
                const previousKeyExists = this.preloadedImageKeys.find((v) => v === buttons.previousImageId);
                if (!previousKeyExists) {
                    imageUrl = `${APP_DEBUG === true ? BASE_URL_DEBUG : BASE_URL}/display-image/${buttons.previousImagePublicUrl}.jpg`;
                    actions.preloadImagesRequest({ id: buttons.previousImageId, url: imageUrl });
                    this.preloadedImageKeys.push(buttons.previousImageId);
                }
            }

            if (buttons && buttons.nextImagePublicUrl) {
                const nextKeyExists = this.preloadedImageKeys.find((v) => v === buttons.nextImageId);
                if (!nextKeyExists) {
                    imageUrl = `${APP_DEBUG === true ? BASE_URL_DEBUG : BASE_URL}/display-image/${buttons.nextImagePublicUrl}.jpg`;
                    actions.preloadImagesRequest({ id: buttons.nextImageId, url: imageUrl });
                    this.preloadedImageKeys.push(buttons.nextImageId);
                }
            }
        }
    }

    componentWillUnmount() {
        const { actions } = this.props;
        actions.resetPreloadImagesRequest();
    }

    toggleStatusExplanationModal = (status) => this.setState({ statusExplanationModalOpen: status });

    showConfirm = (type) => {
        const {
            actions, params, nextImage, selectedProduction, buttonsArray
        } = this.props;
        const buttons = buttonsArray[this.state.renderedImageId];

        const allowedSoloLimit = selectedProduction.currentUser.soloKillLimit === 0 ? -1 : Math.ceil((selectedProduction.currentUser.soloKillLimit / 100) * selectedProduction.totalProductionImages);
        const allowedGroupLimit = selectedProduction.currentUser.groupKillLimit === 0 ? -1 : Math.ceil((selectedProduction.currentUser.groupKillLimit / 100) * selectedProduction.totalProductionImages);

        const that = this;
        let showWarningDialog = lockr.get(CONFIRM_DIALOG);
        if (_.isUndefined(showWarningDialog)) {
            showWarningDialog = false;
        }
        // Dialog removed on request by Aimee, if wanted again just remove the below line
        showWarningDialog = true;
        if (showWarningDialog === false) {
            let confirmTitle = '';
            let confirmDescription = '';
            switch (type) {
            case 'KILL':
                confirmTitle = 'Are you sure to kill this image?';
                confirmDescription = 'Killing an image will kill it for everyone.';
                break;
            case 'UN_KILL':
                confirmTitle = 'Are you sure to un-kill this image?';
                confirmDescription = 'If someone has already killed the image, overall status of the image will stay as killed.';
                break;
            case 'KILL_AND_EXCLUDE':
                confirmTitle = 'Are you sure to kill and exclude this image?';
                confirmDescription = 'This marks the image as killed, and hides the image from any tagged actors so they will not see it when they log in. The image will not count towards tagged actors total image count or kill allowance.';
                break;
            case 'UN_EXCLUDE':
                confirmTitle = 'Are you sure to un-kill this image?';
                confirmDescription = 'The image will now be visible again to all tagged actors, and count towards their total image count and kill allowance.';
                break;
            default:
                confirmTitle = '';
                confirmDescription = '';
            }

            const content = (
                <div>
                    <Row style={{ marginTop: '30px' }}>
                        <Col xs={24}>{confirmDescription}</Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs={24}><Checkbox onChange={(e) => this.setState({ warningDialogChange: e.target.checked })}>{'Don\'t show this message again'}</Checkbox></Col>
                    </Row>
                </div>
            );

            Modal.confirm({
                title: confirmTitle,
                content,
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    lockr.set(CONFIRM_DIALOG, that.state.warningDialogChange);
                    actions.userKillImageRequest({
                        productionSlug: params.production,
                        daySlug: params.day,
                        imageName: params.image,
                        action: type,
                        nextImage,
                        allowedSoloLimit,
                        allowedGroupLimit,
                        requestLocation: params.status,
                    });

                    if (buttons.nextImageId) {
                        this.setState({
                            renderedImageId: buttons.nextImageId,
                            imageChanged: true,
                            isImageLoading: true,
                        });
                    }
                },
            });
        } else {
            actions.userKillImageRequest({
                productionSlug: params.production,
                daySlug: params.day,
                imageName: params.image,
                action: type,
                nextImage,
                allowedSoloLimit,
                allowedGroupLimit,
                requestLocation: params.status,
            });
            if (buttons && buttons.nextImageId) {
                this.setState({
                    renderedImageId: buttons.nextImageId,
                    imageChanged: true,
                    isImageLoading: true,
                });
            }
        }
    }

    showDeleteTagConfirm = (e, userId) => {
        const { actions, params } = this.props;
        const that = this;
        e.preventDefault();
        Modal.confirm({
            title: 'Are you sure to remove tagged user from this image?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                actions.removeTagUserRequest({
                    productionSlug: params.production,
                    daySlug: params.day,
                    imageName: params.image,
                    user_id: userId,
                });
                that.setState({ newUserTag: null });
            },
        });
    }

    removeTagUser = (userId) => {
        const { actions, params } = this.props;
        actions.removeTagUserRequest({
            productionSlug: params.production,
            daySlug: params.day,
            imageName: params.image,
            user_id: userId,
        });
    }

    showTaggedUsersModal = (e) => {
        e.preventDefault();
        const { actions, params } = this.props;
        this.setState({ taggedUserModelVisible: true });
    }

    addNewTagUser = (e) => {
        e.preventDefault();
        const { actions, params } = this.props;
        const { newUserTag } = this.state;
        if (!_.isNull(newUserTag) && newUserTag !== '') {
            actions.addTagUserRequest({
                productionSlug: params.production,
                daySlug: params.day,
                imageName: params.image,
                user_id: { [newUserTag]: true },
            });
            this.setState({ newUserTag: null });
        }
        // eslint-disable-next-line no-undef
        window.location.reload();
    }

    render() {
        return this.view();
    }
}

ImageSingle.defaultProps = {
    params: {},
    nextImage: '',
    selectedProduction: {},
    buttons: {},
    selectedProductionDayImage: {},
    preloadImages: {},
};

ImageSingle.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object,
    nextImage: PropTypes.string,
    selectedProduction: PropTypes.object,
    buttons: PropTypes.object,
    selectedProductionDayImage: PropTypes.object,
    preloadImages: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProduction: state.production.selectedProduction,
        selectedProductionDayImage: state.production.selectedProductionDayImage,
        isFetching: state.production.isFetching,
        isSingleImageFetching: state.production.isSingleImageFetching,
        user: state.user.user,
        isActorsFetching: state.production.isActorsFetching,
        allActors: state.production.allActors,
        preloadImages: state.production.preloadImages,
        imageTagModeOn: state.global.imageTagModeOn,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImageSingle);
