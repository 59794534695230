import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';
import DOM from './ImageTagArea';

import * as globalActions from '../../core/global/globalActions';
import * as productionActions from '../../core/production/productionActions';
  
class ImageTagArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historyKey: 0,
            selectedActors: {},
            focusedIndex: null,
            actors: null,
            flashIndex: null,
            allActors: null,
            newTagActor: {},
            taggedUsers: []
        };
        this.qwertyKeys = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Z', 'C', 'V', 'B', 'N', 'M'];
        this.checkboxRefs = {};
        this.view = DOM;
    }

    componentDidMount() {
        // eslint-disable-next-line no-undef
        window.addEventListener('keydown', this.handleKeyDown);
        this.debouncedToggleSelectionAndAction = this.debounce(this.toggleSelectionAndAction, 500);
    }

    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
        const { actions, params } = this.props;
        if (this.props.allActors !== prevProps.allActors) {
            if (this.props.allActors.length > 0) {
                const actors = this.props.allActors
                    .filter(actor => actor.user && actor.user.id)
                    .map(actor => ({ id: actor.user.id, username: actor.user.username }))
                    .sort((actorA, actorB) => actorA.username.localeCompare(actorB.username));
                this.setState({
                    actors
                });
                this.props.taggedUsers.forEach(taggedUserId => {
                    const actorsTag = actors.find(actor => parseInt(actor.id, 10) === taggedUserId.id);
                    if (actorsTag) {
                        this.toggleActorSelection(parseInt(actorsTag.id, 10));
                    }
                });
            }
            setTimeout(() => {
                actions.addTagCombinationRequest(this.state.selectedActors);
            }, 300);
        }
        
        if (this.props.selectedProductionDayImage.id !== prevProps.selectedProductionDayImage.id) {
            setTimeout(() => {
                actions.addTagCombinationRequest(this.state.selectedActors);
            }, 300);

            this.setState({
                selectedActors: {},
                newTagActor: {}
            });

            const actors = this.props.allActors
                .filter(actor => actor.user && actor.user.id) // Filter actors with user and user.id
                .map(actor => ({ id: actor.user.id, username: actor.user.username }))
                .sort((actorA, actorB) => actorA.username.localeCompare(actorB.username));
            this.setState({
                actors
            });

            if (params.image === prevProps.params.image) {
                this.setState({ taggedUsers: this.props.taggedUsers });
                this.props.taggedUsers.forEach(taggedUserId => {
                    const actorsTag = this.state.actors.find(actor => parseInt(actor.id, 10) === taggedUserId.id);
                    if (actorsTag) {
                        this.toggleActorSelection(parseInt(actorsTag.id, 10));
                    }
                });
            } else {
                this.state.taggedUsers.forEach(taggedUserId => {
                    const actorsTag = this.state.actors.find(actor => parseInt(actor.id, 10) === taggedUserId.id);
                    if (actorsTag) {
                        this.toggleActorSelection(parseInt(actorsTag.id, 10));
                    }
                });
            }
        }

        if (prevState.selectedActors !== this.state.selectedActors) {
            setTimeout(() => {
                actions.addTagCombinationRequest(this.state.selectedActors);
            }, 500);
        }
    }

    debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }

    handleKeyDown = (event) => {
        const pressedKey = event.key.toUpperCase();
        if (pressedKey >= '1' && pressedKey <= '9') {
            const actorIndex = parseInt(pressedKey, 10) - 1;
            if (actorIndex < this.state.actors.length) {
                const actorId = this.state.actors[actorIndex].id;
                this.toggleNewActorSelection(actorId);
            }
        } else if (this.qwertyKeys.includes(pressedKey)) {
            const actorIndex = this.qwertyKeys.indexOf(pressedKey) + 9;
            const actorId = this.state.actors[actorIndex].id;
            this.toggleNewActorSelection(actorId);
        } else if (pressedKey === 'X') {
            this.handleClearAll();
        } else if (pressedKey === '0') {
            this.loadTagHistory();
        }
    };

    toggleActorSelection = (actorId) => {
        this.setState(prevState => {
            const selectedActors = { ...prevState.selectedActors };
            if (selectedActors !== this.selectedActors) {
                if (selectedActors[actorId]) {
                    delete selectedActors[actorId];
                } else {
                    selectedActors[actorId] = true;
                }
            }
            return { selectedActors };
        });

        this.setState({ flashIndex: actorId });
        setTimeout(() => {
            this.setState({ flashIndex: null });
        }, 1000);
    };

    toggleNewActorSelection = (actorId) => {
        this.debouncedToggleSelectionAndAction();

        this.setState(prevState => {
            const selectedActors = { ...prevState.selectedActors };
            const newTagActor = { ...prevState.newTagActor };
            if (selectedActors[actorId]) {
                selectedActors[actorId] = false;
            } else {
                selectedActors[actorId] = true;
            }
            if (newTagActor[actorId]) {
                newTagActor[actorId] = false;
            } else {
                newTagActor[actorId] = true;
            }
            return { selectedActors, newTagActor };
        });

        this.setState({ flashIndex: actorId });
        setTimeout(() => {
            this.setState({ flashIndex: null });
        }, 1000);
    };

    handleClearAll = () => {
        const { selectedActors } = this.state;
        Object.keys(selectedActors).forEach(actorId => {
            if (selectedActors[actorId]) {
                this.removeTagUser(parseInt(actorId, 10));
            }
        });
        this.setState({
            selectedActors: {}
        });
    }

    removeTagUser = (actorId) => {
        const { actions, params } = this.props;
        actions.removeTagUserRequest({
            productionSlug: params.production,
            daySlug: params.day,
            imageName: params.image,
            user_id: actorId,
        });
    }

    addNewTagUser = (actorId) => {
        const { actions, params } = this.props;
        actions.addTagUserRequest({
            productionSlug: params.production,
            daySlug: params.day,
            imageName: params.image,
            user_id: actorId,
        });
    }
    
    toggleSelectionAndAction = () => {
        const { selectedActors, newTagActor } = this.state;
        const commonKeys = Object.keys(newTagActor).filter((id) => Object.keys(selectedActors).includes(id));
        const newActor = {};

        commonKeys.forEach((key) => {
            newActor[key] = selectedActors[key];
        });
        if (Object.keys(newActor)) {
            this.addNewTagUser(newActor);
        }
        this.setState({ newTagActor: {} });
    }

    loadTagHistory() {
        const { historyKey, selectedActors } = this.state;
        const { tagHistory } = this.props;
        // Get the next key and tag combination from tagHistory
        const newIndex = historyKey === tagHistory.length - 1 ? 0 : historyKey + 1;
        this.setState({ historyKey: newIndex });
        const currentTagHistory = tagHistory[historyKey];

        // Filter out keys from currentData that are already selected in selectedActors
        const filteredData = Object.keys(currentTagHistory)
            .filter((key) => !selectedActors[key])
            .reduce((obj, key) => {
                // eslint-disable-next-line no-param-reassign
                obj[key] = currentTagHistory[key];
                return obj;
            }, {});

        // If currentData is false but selectedActors is true, select data from currentData
        Object.keys(selectedActors).forEach((key) => {
            if (selectedActors[key] && currentTagHistory[key] === false) {
                filteredData[key] = currentTagHistory[key];
            }
        });

        // Set any remaining keys in selectedActors to false
        Object.keys(selectedActors).filter((id) => !Object.keys(currentTagHistory).includes(id)).forEach((key) => {
            filteredData[key] = false;
        });

        Object.keys(filteredData).forEach((key) => {
            this.toggleActorSelection(key);
        });

        this.addNewTagUser(filteredData);
    }

    isMobileDevice() {
        // eslint-disable-next-line no-undef
        return /Mobi|Android/i.test(navigator.userAgent);
    }

    render() {
        return this.view();
    }
}

ImageTagArea.defaultProps = {
    selectedProductionDayImage: null,
    nextImage: '',
};

ImageTagArea.propTypes = {
    actions: PropTypes.object.isRequired,
    selectedProductionDayImage: PropTypes.object,
    nextImage: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedProductionDay: state.production.selectedProductionDay,
        selectedProductionDayImage: state.production.selectedProductionDayImage,
        imageTagModeOn: state.global.imageTagModeOn,
        allActors: state.production.allActors,
        tagHistory: state.production.tagHistory,
        isFetching: state.production.isFetching
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...productionActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImageTagArea);
