/* global Blob:true */
/* eslint-disable */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import alertify from 'alertify.js';
import _ from 'underscore';
import { saveAs } from 'file-saver';
import { browserHistory } from 'react-router';
import { fetchApiAuth } from '../utils/api';
import convert2base64 from '../utils/utils';
import { createBrowserHistory } from "history";

import {
    getUserProductionSuccess,
    getUserProductionFailure,
    getUserSingleProductionRequest,
    getUserSingleProductionSuccess,
    getUserSingleProductionFailure,
    getUserSingleProductionDaySuccess,
    getUserSingleProductionDayFailure,
    getUserSingleImageSuccess,
    getUserSingleImageFailure,
    userKillImageSuccess,
    userKillImageFailure,
    userMarkDayDoneSuccess,
    userMarkDayDoneFailure,
    getAllResellersSuccess,
    getAllResellersFailure,
    sendAccessRequestSuccess,
    sendAccessRequestFailure,
    getAllActorsSuccess,
    getAllActorsFailure,
    addTagUserSuccess,
    addTagUserFailure,
    removeTagUserSuccess,
    removeTagUserFailure,
    getDownloadQueueSuccess,
    getDownloadQueueFailure,
    getDownloadFileSuccess,
    getDownloadFileFailure,
    getDownloadImageSuccess,
    getDownloadImageFailure,
    setSelectedActorSuccess,
    setImageSelectedFlagSuccess,
    setImageSelectedFlagFailure,

    updateImageCaptionSuccess,
    updateImageCaptionFailure,

    preloadImagesSuccess,
    preloadImagesFailure,

    getProductionUserStatsSuccess,
    getProductionUserStatsFailure,
} from './productionActions';

const {
    GET_USER_PRODUCTION_REQUEST,
    GET_USER_SINGLE_PRODUCTION_REQUEST,
    GET_USER_SINGLE_PRODUCTION_DAY_REQUEST,
    GET_USER_SINGLE_IMAGE_REQUEST,
    USER_KILL_IMAGE_REQUEST,
    USER_MARK_DAY_DONE_REQUEST,
    GET_ALL_RESELLERS_REQUEST,
    SEND_ACCESS_REQUEST_REQUEST,
    GET_ALL_ACTORS_REQUEST,
    ADD_TAG_USER_REQUEST,
    REMOVE_TAG_USER_REQUEST,
    GET_DOWNLOAD_QUEUE_REQUEST,
    GET_DOWNLOAD_FILE_REQUEST,
    GET_DOWNLOAD_IMAGE_REQUEST,
    SET_SELECTED_ACTOR_REQUEST,
    SET_IMAGE_SELECTED_FLAG_REQUEST,
    UPDATE_IMAGE_CAPTION_REQUEST,
    PRELOAD_IMAGES_REQUEST,
    GET_PRODUCTION_USER_STATS_REQUEST,
} = require('./productionActions').constants;


function* getUserProductions(action) {
    try {
        const params = !_.isUndefined(action.payload) && !_.isUndefined(action.payload.resellerId) ? { resellerId: action.payload.resellerId } : {};
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/production',
            params
        });
        yield put(getUserProductionSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(getUserProductionFailure(e.response ? e.response.data.message : e));
    }
}

function* getProductionUserStats(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/production/${action.payload.slug}/stats`,
        });
        yield put(getProductionUserStatsSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(getProductionUserStatsFailure(e.response ? e.response.data.message : e));
    }
}

function* getUserSingleProduction(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/production/${action.payload.slug}`,
            params: {
                invalidateCache: action?.payload?.invalidateCache || false
            }
        });
        yield put(getUserSingleProductionSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(getUserSingleProductionFailure(e.response ? e.response.data.message : e));
    }
}

function* getUserSingleProductionDay(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/production/${action.payload.productionSlug}/${action.payload.daySlug}`,
        });
        yield put(getUserSingleProductionDaySuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(getUserSingleProductionDayFailure(e.response ? e.response.data.message : e));
    }
}

function* getUserSingleProductionDayImage(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/production/${action.payload.productionSlug}/${action.payload.daySlug}/${action.payload.imageName}`,
            params: {
                callFrom: action.payload.callFrom
            }
        });

        yield put(getUserSingleImageSuccess({...response, user: action.payload.user}));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        browserHistory.push('/production')
        yield put(getUserSingleImageFailure(e.response ? e.response.data.message : e));
    }
}

function* killImageRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/production/${action.payload.productionSlug}/${action.payload.daySlug}/${action.payload.imageName}`,
            body: {
                action: action.payload.action,
                totalUserImages: action.payload.totalProductionImages
            }
        });


        yield put(getUserSingleProductionRequest({slug: action.payload.productionSlug}))
        yield put(userKillImageSuccess(response));

        if (!_.isNull(action.payload.nextImage) && response.data.limitReached === false) {
            browserHistory.push(action.payload.nextImage);
        } else {

            if(action.payload.requestLocation == 'viewed' || action.payload.requestLocation == 'kill-list') {
                setTimeout(() => window.location.reload(), 500);
                // setTimeout(() => browserHistory.push(`/production/${action.payload.productionSlug}/${action.payload.daySlug}/${action.payload.imageName}/${action.payload.requestLocation}`), 200);
            }

            // window.location.reload();
            // browserHistory.push(`/production/${action.payload.productionSlug}/${action.payload.daySlug}/${action.payload.imageName}/kill-list`);
        }


    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(userKillImageFailure(e.response ? e.response.data.message : e));
    }
}

function* markDoneDayRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/production/${action.payload.productionSlug}/${action.payload.daySlug}`,
            body: {
                action: 'MARK_DONE'
            }
        });
        yield put(userMarkDayDoneSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(userMarkDayDoneFailure(e.response ? e.response.data.message : e));
    }
}

function* getResellersList(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'reseller-list',
        });
        yield put(getAllResellersSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(getAllResellersFailure(e.response ? e.response.data.message : e));
    }
}

function* sendAccessRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'request-access',
            body: action.payload
        });
        alertify.success('Your request has been sent and we will be in touch with you shortly.');
        yield put(sendAccessRequestSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(sendAccessRequestFailure(e.response ? e.response.data.message : e));
    }
}

function* getAllActors(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `production/${action.payload.production}/${action.payload.day}/actors-list`
        });
        yield put(getAllActorsSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(getAllActorsFailure(e.response ? e.response.data.message : e));
    }
}

function* addTagActor(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `production/${action.payload.productionSlug}/${action.payload.daySlug}/${action.payload.imageName}/update-tag-actor`,
            body: {
                user_id: action.payload.user_id
            }
        });
        yield put(addTagUserSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(addTagUserFailure(e.response ? e.response.data.message : e));
    }
}

function* removeTagActor(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `production/${action.payload.productionSlug}/${action.payload.daySlug}/${action.payload.imageName}/remove-tag-actor`,
            body: {
                user_id: action.payload.user_id
            }
        });
        yield put(removeTagUserSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(removeTagUserFailure(e.response ? e.response.data.message : e));
    }
}

function* getDownloadQueue(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `production/${action.payload.productionSlug}/downloads`
        });
        yield put(getDownloadQueueSuccess(response));
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(getDownloadQueueFailure(e.response ? e.response.data.message : e));
    }
}

function* getDownloadFile(action) {
    const responseType = action.payload.actionType === 'download' ? 'blob' : 'json';
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `production/${action.payload.productionSlug}/download`,
            body: {
                listType: action.payload.listType,
                action: action.payload.actionType,
                fileType: action.payload.fileType
            },
            responseType
        });
        if (action.payload.actionType === 'download') {
            const fileSaverOptions = action.payload.fileType === 'xlsx' ? { type: 'application/vnd.ms-excel' } : { type: 'application/pdf' };
            const blob = new Blob([response.data], fileSaverOptions);
            saveAs(blob, action.payload.file);
            alertify.success('File downloaded successfully.');
        } else {
            alertify.success('Your request has been received. File generation in progress.');
        }
        yield put(getDownloadFileSuccess(response));
    } catch (e) {
        let error = e.response ? e.response.data.message : e;
        if (responseType === 'blob') {
            error = {};
            error.data = 'No data found for your request.';
            alertify.error('No data found for your request.');
        } else {
            alertify.error(e.response ? e.response.data.message : e);
        }
        yield put(getDownloadFileFailure(error));
    }
}

function* getDownloadImage(action) {
    const responseType = 'blob';
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `production/${action.payload.productionSlug}/${action.payload.productionDaySlug}/${action.payload.imageName}/download`,
            body: {},
            responseType
        });
        const fileSaverOptions = { type: 'image/jpg' };
        const blob = new Blob([response.data], fileSaverOptions);
        saveAs(blob, action.payload.imageName+".jpg");
        alertify.success('File downloaded successfully.');
        yield put(getDownloadImageSuccess(response));
    } catch (e) {
        let error = {};
        error.data = 'No data found for your request.';
        alertify.error('No data found for your request.');
        yield put(getDownloadImageFailure(error));
    }
}

function* setSelectedActor(action) {
    yield put(setSelectedActorSuccess(action.payload));
}

function* toggleImageSelect(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'production/toggle-image-select',
            body: {
                productionSlug: action.payload.productionSlug,
                productionDaySlug: action.payload.productionDaySlug,
                imageName: action.payload.imageName
            }
        });
        yield put(setImageSelectedFlagSuccess(response));
        let selected = true;
        if (response.data && response.data.is_selected && response.data.is_selected == 0) {
            selected = false;
        }
        alertify.success(selected ? 'Image marked as selected' : 'Image un-marked as selected');
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(setImageSelectedFlagFailure(e.response ? e.response.data.message : e));
    }
}


function* updateImageCaption(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'production/update-image-caption',
            body: action.payload
        });
        yield put(updateImageCaptionSuccess(response));
        alertify.success('Succesfully updated image caption');
    } catch (e) {
        alertify.error(e.response ? e.response.data.message : e);
        yield put(updateImageCaptionFailure(e.response ? e.response.data.message : e));
    }
}

function* preloadImages(action) {
    try {
        const response = yield call(convert2base64, action.payload.url);
        yield put(preloadImagesSuccess({ id: action.payload.id, response: response }));
    } catch (e) {
        yield put(preloadImagesFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* productionSaga() {
    yield* [
        takeEvery(GET_USER_PRODUCTION_REQUEST, getUserProductions),
        takeEvery(GET_USER_SINGLE_PRODUCTION_REQUEST, getUserSingleProduction),
        takeEvery(GET_USER_SINGLE_PRODUCTION_DAY_REQUEST, getUserSingleProductionDay),
        takeEvery(GET_USER_SINGLE_IMAGE_REQUEST, getUserSingleProductionDayImage),
        takeEvery(USER_KILL_IMAGE_REQUEST, killImageRequest),
        takeEvery(USER_MARK_DAY_DONE_REQUEST, markDoneDayRequest),
        takeEvery(GET_ALL_RESELLERS_REQUEST, getResellersList),
        takeEvery(SEND_ACCESS_REQUEST_REQUEST, sendAccessRequest),
        takeEvery(GET_ALL_ACTORS_REQUEST, getAllActors),
        takeEvery(ADD_TAG_USER_REQUEST, addTagActor),
        takeEvery(REMOVE_TAG_USER_REQUEST, removeTagActor),
        takeEvery(GET_DOWNLOAD_QUEUE_REQUEST, getDownloadQueue),
        takeEvery(GET_DOWNLOAD_FILE_REQUEST, getDownloadFile),
        takeEvery(GET_DOWNLOAD_IMAGE_REQUEST, getDownloadImage),
        takeEvery(SET_SELECTED_ACTOR_REQUEST, setSelectedActor),
        takeEvery(SET_IMAGE_SELECTED_FLAG_REQUEST, toggleImageSelect),
        takeLatest(UPDATE_IMAGE_CAPTION_REQUEST, updateImageCaption),
        takeEvery(PRELOAD_IMAGES_REQUEST, preloadImages),
        takeEvery(GET_PRODUCTION_USER_STATS_REQUEST, getProductionUserStats),
    ];
}
