/* eslint-disable react/no-this-in-sfc */
import { Link } from 'react-router';
import {
    Modal,
    Icon,
    Row,
    Col,
    Button,
    Spin,
} from 'antd';
import React from 'react';
import {
    isNull, map, filter, isUndefined, has
} from 'underscore';
import moment from 'moment';

import './image-single.css';
import ButtonIcon from '../../elements/ButtonIcon';
import CustomIcon from '../../elements/Icon';
import ImageTagArea from '../ImageTagArea';
import { IMAGE_VIEW_ALL, ROLE_ACTOR, ROLE_VIEWER } from '../../core/utils/value';

const {
    APP_DEBUG,
    BASE_URL,
    BASE_URL_DEBUG
} = require('../../core/constants').default;

export default function () {
    const {
        selectedProductionDayImage, user, allActors, isFetching, isSingleImageFetching, preloadImages, buttonsArray, imageTagModeOn, selectedProduction, params
    } = this.props;
    let imageUrl = null;
    let taggedUsers = [];
    let imageKillers = [];
    let actorsCount = 0;
    let selectedProductioDayImagesUsers = [];
    let displayURL22 = '';
    let filteredUsers = [];
    let currentUser = {};

    let buttons = buttonsArray[this.state.renderedImageId];
    if (!buttons) {
        buttons = {
            previousImageName: null,
            previousImageUrl: null,
            previousImageId: null,
            previousImagePublicUrl: null,
            nextImageName: null,
            nextImageUrl: null,
            nextImageId: null,
            nextImagePublicUrl: null,
            imageFound: false,
            isNextImage: false,
        };
    }

    if (!isFetching && !isNull(selectedProductionDayImage)) {
        imageUrl = `${APP_DEBUG === true ? BASE_URL_DEBUG : BASE_URL}/display-image/${selectedProductionDayImage.publicUrl}.jpg`;
        displayURL22 = `${APP_DEBUG === true ? BASE_URL_DEBUG : BASE_URL}/display-image/${selectedProductionDayImage.publicUrl}.jpg`;
        actorsCount = selectedProductionDayImage.users.filter((u) => u.user_type === 4).length;
        currentUser = selectedProduction.currentUser;
        selectedProductioDayImagesUsers = filter(selectedProductionDayImage.users, (u) => u.user_type === 4);
        filteredUsers = currentUser.user_type === ROLE_ACTOR
            ? filter(selectedProductionDayImage.users, (productionUser) => parseInt(productionUser.id, 10) === parseInt(user.UID, 10) && parseInt(productionUser.user_type, 10) === 4)
            : filter(selectedProductionDayImage.users, (productionAgentUser) => parseInt(productionAgentUser.user_type, 10) === 4);

        taggedUsers = map(filteredUsers, (filterUser, filterUserIndex) => {
            let iconName = 'eye-not';
            if (filterUser.killed === 1) {
                iconName = 'deleted';
            } else if (!isNull(filterUser.viewed_on)) {
                iconName = 'eye';
            }
            let userType = null;
            if (parseInt(filterUser.type, 10) === 3) {
                userType = ' (Agent)';
            } else if (parseInt(filterUser.type, 10) === 2) {
                userType = ' (Reseller)';
            } else if (parseInt(filterUser.type, 10) === 1) {
                userType = ' (Admin)';
            }
            return (
                <div className="imagesingle__killer" key={filterUserIndex}>
                    <CustomIcon name={iconName} />
                    <span>
                        {filterUser.full_name}
                        {userType}
                    </span>
                </div>
            );
        });

        if (taggedUsers.length === 0) {
            taggedUsers = <span style={{ marginLeft: 5 }}>No tagged actor found in this image</span>;
        }

        imageKillers = map(filter(selectedProductionDayImage.users, (filteredUser) => parseInt(filteredUser.killed, 10) === 1), (filteredUserMap, userIndexMap) => (
            <p key={userIndexMap}>
                Killed by &quot;
                {filteredUserMap.full_name}
                &quot; on
                {' '}
                {moment(filteredUserMap.killed_on, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
                {' '}
                at
                {' '}
                {moment(filteredUserMap.killed_on, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
            </p>
        ));
    }

    const { newUserTag, taggedUserModelVisible, statusExplanationModalOpen } = this.state;

    let markedDone = false;
    if (selectedProduction && selectedProduction.productionDays && selectedProduction.productionDays[0]) {
        const selectedProductionDay = selectedProduction.productionDays.find((productionDay) => productionDay.slug === params.day);
        if (selectedProductionDay) {
            markedDone = selectedProductionDay.markedDoneFlag;
        }
    }

    const imageCacheIndexExist = has(preloadImages, this.state.renderedImageId);

    return (
        <Spin spinning={!imageCacheIndexExist && this.state.isImageLoading}>
            <div className="imagesingle">
                <div className="container">
                    <div className="imagesingle__inner">
                        <figure className="imagesingle__figure">
                            {!isNull(buttons.previousImagePublicUrl)
                                ? (
                                    <Link
                                        to={buttons.previousImageUrl}
                                        onClick={() => {
                                            this.props.handleImageChange(
                                                true
                                            );
                                            this.setState({ renderedImageId: buttons.previousImageId, imageChanged: true, isImageLoading: true, });
                                        }}
                                        className="btn-empty">
                                        <div className="imagesingle__previous_btn">
                                            <CustomIcon name="arrowback" />
                                        </div>
                                    </Link>
                                )
                                : null}
                            {!isNull(buttons.nextImagePublicUrl)
                                ? (
                                    <Link
                                        to={buttons.nextImageUrl}
                                        onClick={() => {
                                            this.props.handleImageChange(
                                                true
                                            );
                                            this.setState({ renderedImageId: buttons.nextImageId, imageChanged: true, isImageLoading: true, });
                                        }}
                                        className="btn-empty">
                                        <div className="imagesingle__next_btn">
                                            <CustomIcon name="arrownext-bt" />
                                        </div>
                                    </Link>
                                )
                                : null}

                            {imageCacheIndexExist === true
                                ? (
                                    <div key={`single_image_${this.state.renderedImageId}`} className={`single_image_container_${this.state.renderedImageId}`}>
                                        <img src={preloadImages[this.state.renderedImageId]} alt={`single_image_${this.state.renderedImageId}`} className={`single_image_${this.state.renderedImageId}`} data-image-id={this.state.renderedImageId} />
                                    </div>
                                )
                                : null}

                            {!imageCacheIndexExist && imageUrl ? (
                                <div key={`single_image_${this.state.renderedImageId}`} className={`single_image_container_${this.state.renderedImageId}`}>
                                    <img
                                        src={imageUrl}
                                        alt={`single_image_${this.state.renderedImageId}`}
                                        className={`single_image_${this.state.renderedImageId}`}
                                        onLoad={() => this.setState({ isImageLoading: false })}
                                        data-image-id={this.state.renderedImageId} />
                                </div>
                            ) : null}

                            <div className="image-single-action-row">
                                {!isSingleImageFetching && (currentUser.user_type !== ROLE_VIEWER || currentUser.SelectImageViewOption === IMAGE_VIEW_ALL) && selectedProductionDayImage && selectedProductionDayImage.status === 'killed' 
                                    ? (
                                        <div className="image-single-action-icon red">
                                            <Icon type="close" />
                                            <span className="image-single-action-label">Killed</span>
                                        </div>
                                    )
                                    : null}
                                {!isSingleImageFetching && (currentUser.user_type !== ROLE_VIEWER || currentUser.SelectImageViewOption === IMAGE_VIEW_ALL) && selectedProductionDayImage && selectedProductionDayImage.status === 'removed'
                                    ? (
                                        <div className="image-single-action-icon black">
                                            <Icon type="close" />
                                        </div>
                                    )
                                    : null}
                                {!isSingleImageFetching && currentUser.user_type !== ROLE_VIEWER && selectedProductionDayImage && selectedProductionDayImage.hasImageActiveCaption === true && currentUser.user_type !== ROLE_ACTOR
                                    ? (
                                        <div className="image-single-action-icon green">
                                            <Icon theme="filled" type="message" />
                                        </div>
                                    )
                                    : null}
                                {!isSingleImageFetching && currentUser.user_type !== ROLE_VIEWER && selectedProductionDayImage && parseInt(selectedProductionDayImage.is_selected, 10) === 1 && currentUser.user_type !== ROLE_ACTOR
                                    ? (
                                        <div className="image-single-action-icon orange">
                                            <Icon theme="filled" type="star" />
                                        </div>
                                    )
                                    : null}
                            </div>
                        </figure>
                        <div className="imagesingle__info">
                            {currentUser.user_type !== ROLE_VIEWER && currentUser.user_type !== ROLE_ACTOR && !isNull(allActors)
                                ? (
                                    <div className="flex-center-stretch">
                                        <a onClick={this.showTaggedUsersModal}>
                                            <CustomIcon name="edit" className="even-height" />
                                        </a>
                                        <Modal
                                            title="Tagged Users"
                                            footer={null}
                                            onCancel={() => this.setState({ taggedUserModelVisible: false, newUserTag: null })}
                                            visible={taggedUserModelVisible}>
                                            {map(selectedProductioDayImagesUsers, (taggedUser, taggedUserIndex) => (
                                                <Row key={taggedUserIndex} style={{ padding: '10px 0px', borderBottom: '1px dashed #CCC' }}>
                                                    <Col xs={22}>{taggedUser.full_name}</Col>
                                                    <Col xs={2}>
                                                        {isNull(taggedUser.killed) || taggedUser.killed === 0
                                                            ? (
                                                                <a onClick={(e) => this.showDeleteTagConfirm(e, taggedUser.id)}>
                                                                    <Icon type="delete" />
                                                                </a>
                                                            )
                                                            : null}
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Row style={{ marginTop: '40px', marginBottom: '10px' }}>
                                                <Col xs={24}><h3>Add tag</h3></Col>
                                            </Row>
                                            <Row style={{ marginBottom: '10px' }}>
                                                <Col xs={24}>
                                                    <select
                                                        style={{ width: '100%', backgroundColor: '#FFF', height: '35px' }}
                                                        onChange={(event) => this.setState({ newUserTag: event.target.value })}>
                                                        <option value="">Select Actor</option>
                                                        {/* Added filter below to remove any production users selected which doesn't have a user record.  */}
                                                        {map(filter(allActors, (preActor) => !isNull(preActor.user) && !isUndefined(preActor.user)), (actor, actorIndex) => {
                                                            // eslint-disable-next-line
                                                            if (this.state.newUserTag == actor.kato_user_id) {
                                                                return (<option key={actorIndex} value={actor.user.id} selected>{actor.user.profile.full_name}</option>);
                                                            }
                                                            return (<option key={actorIndex} value={actor.user.id}>{actor.user.profile.full_name}</option>);
                                                        })}
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={24}>
                                                    <Button type="primary" onClick={this.addNewTagUser} block disabled={isNull(newUserTag) || newUserTag === ''}>Add</Button>
                                                </Col>
                                            </Row>
                                        </Modal>
                                    </div>
                                )
                                : null}
                            <div className="imagesingle__text">
                                {!isSingleImageFetching
                                    ? (
                                        <div className="imagesingle__text-row">
                                            <span>Tagged:</span>
                                            {' '}
                                            {imageTagModeOn 
                                                ? <div className="imagesingle__text-row">
                                                    {map(selectedProductioDayImagesUsers, (taggedUser, taggedUserIndex) => (
                                                        <Col key={taggedUserIndex} style={{ paddingInline: '5px', flexDirection: 'row' }}>
                                                            <Col xs={22}>{taggedUser.full_name}</Col>
                                                            <Col xs={2}>
                                                                {isNull(taggedUser.killed) || taggedUser.killed === 0
                                                                    ? (
                                                                        <a onClick={(e) => this.removeTagUser(taggedUser.id)}>
                                                                            <Icon type="close" />
                                                                        </a>
                                                                    )
                                                                    : null}
                                                            </Col>
                                                        </Col>
                                                    ))}
                                                </div>
                                                : taggedUsers}
                                            {currentUser.user_type === ROLE_ACTOR && actorsCount === 2 ? (
                                                <span>(and one other)</span>
                                            ) : null }
                                            {currentUser.user_type === ROLE_ACTOR && actorsCount > 2 ? (
                                                <span>
                                                    (and
                                                    {actorsCount - 1}
                                                    {' '}
                                                    others)
                                                </span>
                                            ) : null }
                                        </div>
                                    )
                                    : (
                                        <div className="imagesingle__text-row">
                                            <span>Tagged:</span>
                                        </div>
                                    )}
                                <div className="imagesingle__text-row">
                                    <span>{selectedProductionDayImage && selectedProductionDayImage.production_day ? selectedProductionDayImage.production_day.name : null}</span>
                                </div>
                            </div>
                        </div>
                        <ImageTagArea taggedUsers={filteredUsers} allActors={allActors} params={this.props.params} loading={!imageCacheIndexExist && this.state.isImageLoading} />
                        <div className="imagesingle__actions">
                            {!isSingleImageFetching && !markedDone && currentUser.user_type !== ROLE_VIEWER && selectedProductionDayImage && selectedProductionDayImage.status === 'killed'
                                ? (
                                    <div>
                                        <ButtonIcon text="Unkill" icon="unkill" color="green" onClick={() => { this.showConfirm('UN_KILL'); this.props.handleImageChange(true); }} />
                                        {currentUser.user_type !== ROLE_ACTOR ? imageKillers : null}
                                    </div>
                                )
                                : false}

                            {!isSingleImageFetching && !markedDone && currentUser.user_type !== ROLE_VIEWER && selectedProductionDayImage && selectedProductionDayImage.status === 'removed'
                                ? (
                                    <div>
                                        <ButtonIcon text="Unkill" icon="unkill" color="green" onClick={() => { this.showConfirm('UN_EXCLUDE'); this.props.handleImageChange(true); }} />
                                        {currentUser.user_type !== ROLE_ACTOR ? imageKillers : null}
                                    </div>
                                )
                                : false}

                            {!isSingleImageFetching && !markedDone && currentUser.user_type !== ROLE_VIEWER && selectedProductionDayImage && selectedProductionDayImage.status !== 'removed' && selectedProductionDayImage.status !== 'killed'
                                ? (
                                    <div>
                                        <div className="exclude-buttons-block">
                                            {currentUser.user_type !== ROLE_ACTOR ? <ButtonIcon text="Kill and exclude" icon="deleted-white" color="black" onClick={() => { this.showConfirm('KILL_AND_EXCLUDE'); this.props.handleImageChange(true); }} extraClass="exclude-button" /> : false}
                                            <ButtonIcon text="Kill image" icon="deleted-white" color="red" onClick={() => { this.showConfirm('KILL'); this.props.handleImageChange(true); }} />
                                        </div>
                                        {currentUser.user_type !== ROLE_ACTOR ? imageKillers : null}
                                        {currentUser.user_type !== ROLE_ACTOR ? <a className="link-red" onClick={() => this.toggleStatusExplanationModal(true)}>What is the difference?</a> : null}
                                    </div>
                                )
                                : false}
                        </div>
                    </div>
                </div>

                <Modal
                    title="What is the difference?"
                    footer={null}
                    onCancel={() => this.toggleStatusExplanationModal(false)}
                    visible={statusExplanationModalOpen}>
                    <Row>
                        <Col xs={24}>
                            <h3>Kill image button</h3>
                            <p>Marks the image as killed, but the actor can still see the image when they log in. The image will still count towards the tagged actor&#39;s total image count, but will not be counted towards their kill allowance.</p>

                            <h3>Kill and exclude button</h3>
                            <p>This marks the image as killed, and hides the image from any tagged actors so they will not see it when they log in. The image will not count towards tagged actors total image count or kill allowance.</p>

                            <h3>Unkill button</h3>
                            <p>
                                This button only displays if you have killed the image. Once pressed, the image will be unkilled. Or,
                                if you had previously used the "Kill and exclude" button, then the image will now be visible again
                                to all tagged actors, and count towards their total image count and kill allowance.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} style={{ textAlign: 'center' }}>
                            <Button type="red" onClick={() => this.toggleStatusExplanationModal(false)}>Close</Button>
                        </Col>
                    </Row>
                </Modal>
            </div>
        </Spin>
    );
}
