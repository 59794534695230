/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router';
import { Row, Col, Spin } from 'antd';
import _, { filter } from 'underscore';
import ImageSingle from '../../components/ImageSingle';
import Icon from '../../elements/Icon';
import Navbuttons from '../../components/NavButtons';
import KillStatistics from '../../components/KillStatistics';
import Menu from '../../components/Menu';
import AllowanceWidget from '../../components/AllowanceWidget';
import ImageCaptionArea from '../../components/ImageCaptionArea';
import { ROLE_ACTOR } from '../../core/utils/value';

const { CAPTION_MODE } = require('../../core/constants').default;

export default function () {
    const {
        selectedProductionDayImage, isFetching, selectedProduction, params, location
    } = this.props;
    let link = null;
    let tabKey = null;
    let imageRef = null;

    // Below code is to preload selectedProductionDay
    // There is a two step process normally a user selects a production then production day and then image
    // However from killList user can go directly to image. Here the selectedProductionDay will be null
    // In order to fill selectedProductionDay, it is selected from the previous production request.
    // let customSelectedProductionDay = selectedProductionDay;

    // if (params.status === 'selects' || params.status === 'unkilled-selects') {
    //     const filteredProductionDay = {
    //         ...selectedProductionDay,
    //         productionDays: selectedProductionDay?.productionDays?.map((productionDays) => ({
    //             ...productionDays,
    //             images: _.filter(productionDays.images, (image) => image.is_selected),
    //         })),
    //     };
    //     customSelectedProductionDay = filteredProductionDay;
    // }

    // if (params.status === 'kill-list') {
    //     const filteredProductionDay = {
    //         ...selectedProductionDay,
    //         productionDays: selectedProductionDay?.productionDays?.map((productionDays) => ({
    //             ...productionDays,
    //             images: _.filter(productionDays.images, (image) => image.isKilled),
    //         })),
    //     };
    //     customSelectedProductionDay = filteredProductionDay;
    // }

    let customSelectedProductionDay = selectedProduction.productionDays.filter((day) => day.slug === location.pathname.split('/')[3])[0];

    const selectedProductionDay = selectedProduction; // .productionDays.filter((day) => day.slug === location.pathname.split('/')[3])[0];
    if (params.status === 'selects' || params.status === 'unkilled-selects') {
        const filteredProductionDay = {
            ...selectedProductionDay,
            productionDays: selectedProductionDay?.productionDays?.map((productionDays) => ({
                ...productionDays,
                images: _.filter(productionDays.images, (image) => image.is_selected),
            })),
        };
        customSelectedProductionDay = filteredProductionDay;
    } else if (params.status === 'kill-list' || params.status === 'killed') {
        const filteredProductionDay = {
            ...selectedProductionDay,
            productionDays: selectedProductionDay?.productionDays?.map((productionDays) => ({
                ...productionDays,
                images: _.filter(productionDays.images, (image) => image.isKilled),
            })),
        };
        customSelectedProductionDay = filteredProductionDay;
    } else if (params.status === 'not-killed') {
        const filteredProductionDay = {
            ...selectedProductionDay,
            productionDays: selectedProductionDay?.productionDays?.map((productionDays) => ({
                ...productionDays,
                images: _.filter(productionDays.images, (image) => !image.isKilled),
            })),
        };
        customSelectedProductionDay = filteredProductionDay;
    } else if (params.status === 'viewed' && selectedProductionDay?.currentUser?.user_type !== ROLE_ACTOR) {
        const filteredProductionDay = {
            ...selectedProductionDay,
            productionDays: selectedProductionDay?.productionDays?.map((productionDays) => ({
                ...productionDays,
                images: _.filter(productionDays.images, (image) => !image.isKilled && _.some(image.views, (view) => view.user_id === selectedProductionDay.currentUser.id)),
            })),
        };
        customSelectedProductionDay = filteredProductionDay;
        // }

        // if (params.status === 'viewed' && selectedProductionDay?.currentUser?.user_type === 4) {
        //     const filteredProductionDay = {
        //         ...selectedProductionDay,
        //         productionDays: selectedProductionDay?.productionDays?.map((productionDays) => ({
        //             ...productionDays,
        //             images: _.filter(productionDays.images, (image) => _.some(image.views, (view) => view.user_id === selectedProductionDay.currentUser.id)),
        //         })),
        //     };
        //     customSelectedProductionDay = filteredProductionDay;
        // }

    // if ((params.status === 'inactive' || params.status === 'unviewed') && selectedProductionDay?.currentUser?.user_type !== 4) {
    } else if ((params.status === 'inactive' || params.status === 'unviewed') && selectedProductionDay?.currentUser?.user_type !== ROLE_ACTOR) {
        const filteredProductionDay = {
            ...selectedProductionDay,
            productionDays: selectedProductionDay?.productionDays?.map((productionDays) => ({
                ...productionDays,
                images: _.filter(productionDays.images, (image) => !image.isKilled),
            })),
        };
        customSelectedProductionDay = filteredProductionDay;
    } else {
        const filteredProductionDay = {
            ...selectedProductionDay,
            productionDays: selectedProductionDay?.productionDays?.map((productionDays) => ({
                ...productionDays,
            })),
        };
        customSelectedProductionDay = filteredProductionDay;
    }

    if (params.status === 'inactive' && selectedProductionDay?.currentUser?.user_type === 4) {
        const filteredProductionDay = {
            ...selectedProductionDay,
            productionDays: selectedProductionDay?.productionDays?.map((productionDays) => ({
                ...productionDays,
                // images: _.filter(productionDays.images, (image) => image.views && !_.some(image.views, (view) => view.user_id === selectedProductionDay.currentUser.id))
            })),
        };
        customSelectedProductionDay = filteredProductionDay;
    }
    let nextImageUrl = null;
    let buttons = {
        previousImageId: null,
        previousImageName: null,
        previousImageUrl: null,
        previousImagePublicUrl: null,
        nextImageId: null,
        nextImageName: null,
        nextImageUrl: null,
        nextImagePublicUrl: null,
    };
    if (!_.isNull(selectedProductionDayImage) && this.state.buttonsArray[selectedProductionDayImage.id]) {
        buttons = this.state.buttonsArray[selectedProductionDayImage.id];
        nextImageUrl = buttons.nextImageUrl;
    }

    if (!isFetching && !_.isNull(selectedProductionDayImage) && !_.isNull(customSelectedProductionDay)) {
        this.grabPreviousNextImagesArray(customSelectedProductionDay);
        // imagesArray = this.grabPreviousNextImagesArray(customSelectedProductionDay);
        link = `/production/${customSelectedProductionDay.slug}/${params.day || customSelectedProductionDay.productionDays[0].slug}`;
        // set the link to local storage
        localStorage.setItem('previousUrl', link);

        let restOfUrl = '';
        if (params.day) {
            restOfUrl += `/${params.day}`;
        }

        if (params.status === 'kill-list') {
            link = `/production/${selectedProduction.slug}/kill-list/killed${restOfUrl}`;
            localStorage.setItem('previousUrl', link);
        } else if (params.status === 'selects') {
            link = `/production/${selectedProduction.slug}/selects/select${restOfUrl}`;
            localStorage.setItem('previousUrl', link);
        } else if (params.status === 'not-killed') {
            link = `/production/${selectedProduction.slug}/kill-list/unkilled${restOfUrl}`;
            localStorage.setItem('previousUrl', link);
        } else if (params.status === 'unkilled-selects') {
            link = `/production/${selectedProduction.slug}/selects/unkilled-select${restOfUrl}`;
            localStorage.setItem('previousUrl', link);
        }

        if (params.status === 'viewed') {
            tabKey = 'viewed';
        } else if (params.status === 'unviewed') {
            tabKey = 'unviewed';
        } else if (params.status === 'killed') {
            tabKey = 'killed';
        } else if (params.status === 'all') {
            tabKey = 'all';
        } else {
            tabKey = 'unviewed';
        }
    }
    // get the previous url from local storage
    const previousUrl = localStorage.getItem('previousUrl');

    return (
        <Spin spinning={_.isNull(selectedProductionDayImage)}>
            <div className="page-wrap reviewimage">
                <Helmet title="Image single" />
                <div className="header">
                    <div className="container">
                        <div className="header__inner">
                            <Row type="flex" justify="space-between" align="middle">
                                <Col span={17}>
                                    <div className="flex-center-stretch">
                                        <Menu />
                                        <Link to={{ pathname: link || previousUrl, state: { tabKey } }}>
                                            <Icon name="arrowback" className="even-height" />
                                        </Link>
                                        <span>{selectedProductionDayImage && selectedProductionDayImage.name ? (selectedProductionDayImage.name).toUpperCase() : null}</span>
                                        {selectedProductionDayImage && selectedProductionDayImage.status && selectedProductionDayImage.status === 'killed'
                                            ? <Icon name="deleted" />
                                            : null}
                                    </div>
                                </Col>
                                <Col span={7}>
                                    <AllowanceWidget params={params} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                {this.state.imageChanged || selectedProductionDayImage && selectedProductionDayImage?.name === params.image && !this.state.imageChanged ? (
                    <ImageSingle
                        buttonsArray={this.state.buttonsArray}
                        nextImage={nextImageUrl}
                        selectedProductionDayImage={selectedProductionDayImage}
                        buttons={buttons}
                        handleImageChange={this.handleImageChange}
                        ref={(el) => {
                            imageRef = el;
                        }}
                        params={params} />
                ) : (
                    <div className="spin-center">
                        <Spin spinning />
                    </div>
                )}

                {CAPTION_MODE !== 'false' ? <ImageCaptionArea nextImage={nextImageUrl} /> : null}
                <KillStatistics productionUserStats={selectedProduction} />
                <Navbuttons buttons={buttons} />
            </div>
        </Spin>
    );
}
